import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ILocation from "../../@types/ILocation";
import PointsOfInterest from "../../components/common/PointsOfInterest/PointsOfInterest";
import SearchResult from "../../components/common/Search/SearchResult/SearchResult";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import Title3 from "../../components/global/Title3/Title3";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchSelector, setSearch } from "../../redux/slices/Search/search";
import { IHotelReferences } from "../CityPage/CityPageProps";
import { subTitle2, title } from "./PoiLocation.module.scss";

export const SubTitle2: React.FC<any> = (props) => {
  return <div className={subTitle2}>{props.children}</div>;
};

export const HotelListing = (props: {
  poiLocation: { name: string };
  sortBy?: string;
  location: ILocation;
  hotelSearchFilters: Array<string>;
  isPageIntroAvailable: boolean;
  brandsInUrl: Array<string>;
  hotelReferences: Array<IHotelReferences>;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchDistance, setSearchDistance] = React.useState(50);
  const search = useAppSelector(searchSelector);

  const searchResultTitle = "Featured Hotels Near " + props.poiLocation?.name;
  React.useEffect(() => {
    setSearchDistance(50);
    dispatch(setSearch({ ...search, distance: 50 }));
  }, []);

  React.useEffect(() => {
    setSearchDistance(0); // so that distance from filter can take precendence
  }, [search]);

  return (
    <Container fluid className="bg-light pt-4 pb-4">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={10} className="text-center">
            <SubTitle>Nearby Hotels</SubTitle>
            {props.isPageIntroAvailable ? (
              <Title2>{searchResultTitle}</Title2>
            ) : (
              <Title>{searchResultTitle}</Title>
            )}
          </Col>
        </Row>
      </Container>
      <SearchResult
        background="white"
        distance={searchDistance}
        sortBy={props.sortBy}
        location={props.location}
        hotelSearchFilters={props.hotelSearchFilters}
        brandsInUrl={props.brandsInUrl}
        pageType="poi"
        hotelReferences={props.hotelReferences}
      />
    </Container>
  );
};

export const PopularAttractions = (props: {
  points: Array<any>;
  poiLocation: { name: string };
  isLoading: boolean;
  coordinates: { lat: number; lon: number };
  distance: number;
  setDistance(newdistance: number): void;
}): JSX.Element => {
  return (
    <Container fluid className="pt-4 pb-4">
      {props.points?.length && !props.isLoading ? (
        <Container>
          <Row className="justify-content-md-center">
            <Col md={10} className="text-center">
              <SubTitle id="explore-the-area">Explore the Area</SubTitle>
              <Title3 id="popular-attractions">Popular Attractions</Title3>
              <p id="poi-intro" className="mb-3 mb-lg-4">
                Turn Your Trip Into An Adventure. Explore these{" "}
                {props.poiLocation.name} attractions during your stay.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={10}>
              <PointsOfInterest
                latitude={props.coordinates.lat}
                longitude={props.coordinates.lon}
                points={props.points}
                distance={props.distance}
                setDistance={props.setDistance}
                isLoading={props.isLoading}
                poiLocation={props.poiLocation}
              />
            </Col>
          </Row>
        </Container>
      ) : null}
    </Container>
  );
};
const Title = (props: any) => {
  return <h1 className={title}>{props.children}</h1>;
};

const Title2 = (props: any) => {
  return <h2 className={title}>{props.children}</h2>;
};
